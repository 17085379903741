<!-- https://weatherwidget.io/ -->
<template>
  <div class="outer-container">
    <div
      ref="container"
      class="weatherwidget-io-container"
    >
      <div
        ref="scaleContainer"
        class="scale-container"
      >
        <a
          ref="Iframe"
          class="weatherwidget-io"
          :href="href"
          :data-label_1="primaryLabel"
          :data-label_2="secondaryLabel"
          :data-theme="theme"
          :data-font="font"
          :data-icons="icons"
          :data-basecolor="getBaseColor"
        >
          {{ primaryLabel }} {{ secondaryLabel }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeatherWidget',
  props: {
    primaryLabel: {
      type: String,
      default: () => ''
    },
    /* eslint-disable */
    secondaryLabel: {
      type: String,
      default: () => ''
    },
    href: {
      type: String,
      required: true
    },
    theme: {
      type: String,
      default: () => 'original'
    },
    font: {
      type: String,
      required: false
    },
    icons: {
      type: String,
      required: false
    },
    baseColor: {
      type: String,
      default: null
    }
    /* eslint-enable */
  },
  computed: {
    getBaseColor() {
      return this.baseColor || this.$vuetify.theme.themes.light.primary
    }
  },
  mounted() {
    this.$nextTick(this.weatherWidgetInit())
  },
  updated() {
    this.$nextTick(this.weatherWidgetInit())
  },
  methods: {
    weatherWidgetInit() {
      const fjs = document.getElementsByTagName('script')[0]
      if (!document.getElementById('weatherwidget-io-js')) {
        const js = document.createElement('script')
        js.id = 'weatherwidget-io-js'
        js.src = 'https://weatherwidget.io/js/widget.min.js'
        fjs.parentNode.insertBefore(js, fjs)
      } else {
        setTimeout(window.__weatherwidget_init, 1000)
      }
    }
  }
}
</script>

<style lang="sass">
  .weatherwidget-io-container *
    pointer-events: none
  .outer-container
    height: 6vh
  $fourK: "only screen and (min-width: 2150px) and (min-height: 3830px)"
  @media #{$fourK}
    .weatherwidget-io-container
      width: 50%
      height: 100%
      overflow: visible
    .scale-container
      transform: scale(2) translate(12.5vw, 25%)
</style>
